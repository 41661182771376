<template>
  <div
    class="MiniCartProduct w-full "
    :class="{
      'last:border-none border-b border-light py-12 desk:py-16 first:pt-0': !disableInteraction,
      'pt-8 first:pt-0': disableInteraction
    }"
  >
    <div class="flex">
      <nuxt-link
        v-if="!disableInteraction"
        :to="productUrl(item.url)"
        class="basis-64 h-80 shrink-0 ratio-product block relative flex items-center justify-center"
        :class="{
          'opacity-50': cartStore.isLoading,
        }"
      >
        <div v-if="item.image">
          <nuxt-img
            preset="standard"
            provider="norce"
            loading="lazy"
            sizes="sm:124px"
            :src="item.image"
            class="object-cover"
            :class="{
              'w-48 h-48': !isCustomerSuccessProduct,
              'h-32 w-32': isCustomerSuccessProduct
            }"
          />
          <div class="imageOverlay absolute top-0 left-0 w-full h-full" />
        </div>
        <img
          v-else
          src="/icons/no-image.svg"
          class="aspect-product w-64 rounded-6"
          alt="no image"
        >
        <div
          v-if="item.priceInfo.priceBeforeVat === '0' && !isCustomerSuccessProduct"
          class="absolute -top-6 -left-6 bg-lightest h-28 w-28 rounded-full flex items-center justify-center"
        >
          <div class="h-24 w-24 rounded-full bg-brightGreen flex items-center justify-center">
            <img
              class="w-12 h-12"
              src="/icons/gift.svg"
            >
          </div>
        </div>
      </nuxt-link>
      <div
        class="w-full flex justify-between"
        :class="{
          'ml-16': !disableInteraction
        }"
      >
        <div
          class="type-xs-medium desk:type-sm-medium flex flex-col justify-between"
          :class="{
            'opacity-50': cartStore.isLoading,
            'text-brightRed': usedPoints > 0,
            'w-full -mr-[28px]': isCustomerSuccessProduct
          }"
        >
          <div>
            <span v-if="disableInteraction">
              {{ item.quantity }} x
            </span>
            <nuxt-link :to="productUrl(item.url)">
              {{ item.variantName }}
            </nuxt-link>
            <div v-if="!disableInteraction" class="type-xxs-medium desk:type-xs-medium text-dark">
              {{ item.partNo }}
            </div>
            <div v-if="!disableInteraction" class="flex items-center type-xs text-dark">
              <StockStatus :cart-item="item" />
            </div>
          </div>
          <div v-if="isCustomerSuccessProduct" class="flex items-center w-full border border-light h-32 px-12 type-sm-medium">
            {{ customerSuccessProductMessage }}
          </div>
          <div v-else-if="!disableInteraction" class="flex items-top mt-6 desk:mt-8">
            <div v-if="showQuantityInput" class="w-80">
              <input
                v-model="quantity"
                class="input !pt-0 !h-32"
                type="number"
                min="1"
                :max="onDemand ? 10000 : maxAvailable"
                @input="onEnterQuantityInputDebounced"
              >
            </div>
            <div v-else class="relative w-80 h-32">
              <select
                v-model="selectQuantity"
                class="w-full h-full border border-light bg-lightest pr-16 text-left"
                :class="{ 'cursor-not-allowed hover:border-light opacity-50 disabled': isOrderProposal || disableEditing}"
                :disabled="isOrderProposal || disableEditing"
                @change="onSelectQuantity"
              >
                <option
                  v-for="quantityOption in quantityList"
                  :key="`dropdown-quantity-item-${quantityOption}`"
                  :value="quantityOption"
                >
                  {{ quantityOption }}
                </option>
                <option
                  v-if="!hasRecommendedQuantity && maxAvailable > 25"
                  value="multiple"
                >
                  {{ $t('checkout.amount.more') }}
                </option>
              </select>
              <div
                class="absolute w-32 bg-darkest pointer-events-none top-0 right-0 bottom-0 flex justify-center items-center"
              >
                <img
                  src="/icons/chevron-up-inv.svg"
                  class="inline-block w-16 h-16 rotate-180"
                >
              </div>
            </div>
            <div v-if="!hideFavoriteAction && userStore.canFavorizeProducts" class="bg-lighter ml-8">
              <FavoriteButton :product="item.partNo" design="small" />
            </div>
            <div>
              <div
                v-if="!isOrderProposal && isCheckout && !hideMultiDiscount && !hasMixedProductsCampaign"
              >
                <MultiBuyCTA
                  :item="item"
                  :button-class="showDiscountBtn ? 'mb-8 !h-32':'!h-32'"
                  @update-quantity="updateQuantity"
                />
              </div>

              <div
                v-if="showDiscountBtn"
                class="ml-12"
              >
                <button class="btn btn--secondary !h-32 !text-12 flex items-center" @click="editProductDiscount(item)">
                  {{ $t('checkout.orderProposal.editDiscount') }} <span class="ml-[9px]">%</span>
                </button>
              </div>
            </div>
          </div>

          <div v-if="!disableInteraction && showCrossDockingInput" class="flex mt-12 items-center">
            <input
              v-model="crossDockingValue"
              type="checkbox"
              class="basis-20 shrink-0"
              @click="toggleCrossDocking"
            >
            <span class="pl-12 type-xs-medium desk:type-sm-medium leading-single flex items-center">
              {{ $t('stock.sendWhenInStock') }} <span v-if="globalStore.crossDockingPrices?.priceBeforeVatDisplay" class="ml-2">(+{{ globalStore.crossDockingPrices.priceBeforeVatDisplay }})</span>
              <Tooltip
                class="ml-6"
                :text="$t('checkout.backorder.sendWhenInStock')"
                :show-on-hover="true"
              >
                <img
                  class="w-12 h-12 desk:w-16 desk:h-16 -translate-y-1"
                  src="/icons/info.svg"
                >
              </Tooltip>
            </span>
          </div>
          <div v-if="lastError && lastErrorCode === 422 && !disableInteraction && Array.isArray(lastError.errors) && lastError.errors?.filter((e) => e.partNo === item.partNo).length" class="text-brightRed mt-12">
            <small>{{ $t('stock.noLongerInStock') }}</small>
          </div>
        </div>
        <div
          class="flex flex-col justify-between items-end"
        >
          <button
            v-if="!disableInteraction && !hideDelete && !disableEditing"
            class="pl-16 relative top-4"
            @click="remove"
          >
            <img
              src="/icons/trashcan.svg"
              class="w-16"
              alt="trash"
            >
          </button>
          <div
            v-if="!isCustomerSuccessProduct"
            class="text-right"
            :class="{
              'opacity-50': cartStore.isLoading,
            }"
          >
            <div
              class="type-xs-medium desk:type-sm-medium whitespace-nowrap"
              :class="{
                'text-brightRed': usedPoints > 0 || item.priceInfoTotal.price !== item.priceInfoTotal.priceBeforeDiscount && !disableInteraction,
                'mt-12' : salesRepOfferDiscount > 0
              }"
            >
              {{ item.priceInfoTotal.priceBeforeVatDisplay }}
            </div>

            <div
              v-if="item.priceInfoTotal.price !== item.priceInfoTotal.priceBeforeDiscount && !disableInteraction"
              class="type-xxs-medium desk:type-xs-medium text-darker whitespace-nowrap mt-4"
            >
              {{
                $t('minicart.summary.originalPrice', {price: item.priceInfoTotal.priceBeforeDiscountBeforeVatDisplay})
              }}
            </div>
            <div v-if="salesRepOfferDiscount > 0" class="type-xs-medium text-darker whitespace-nowrap mt-4">
              <span v-if="userStore.isSalesRepUser && salesRepOfferDiscount === 100">{{ $t('checkout.gift') }}</span>
              <span v-else>
                {{ $t('productPage.price.yourDiscount') }} <span class="text-brightRed">-{{ salesRepOfferDiscount.toFixed() }}%</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="usedPoints > 0" class="flex justify-between text-dark leading-single type-sm-medium mt-8">
      <div>{{ $t('pointshop.product.isPoints') }}</div>
      <div>{{ $t('pointshop.product.showPoints', { num: usedPoints }) }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCartStore } from '~/store/cart';
import { useUiStore } from '~/store/ui';
import { useUserStore } from '~/store/user';
import { useGlobalContentStore } from '~/store/globalContent';
import { CartApiItem } from '~/constants/types/norce';
import useLinkReplacer from '~/composeables/useLinkReplacer';
import FavoriteButton from '~/components/product/FavoriteButton.vue';
import debounce from '~/util/debounce';
import { NorceFlagCodes, NorceInfoTypesCodes } from '~/constants/norceCodes';
import MultiBuyCTA from '~/components/product-page/MultiBuyCTA.vue';
import useApiFetch from '~/composeables/useApiFetch';
import StockStatus from '~/components/product/StockStatus.vue';
import Tooltip from '~/components/body/Tooltip.vue';

const { lastError, lastErrorCode } = useApiFetch();
const cartStore = useCartStore();
const uiStore = useUiStore();
const userStore = useUserStore();
const globalStore = useGlobalContentStore();
const { $t } = useNuxtApp();
const { productUrl } = useLinkReplacer();

const route = useRoute();

const isCheckout = computed(()=> {
  return route.name?.toString()?.endsWith('checkout');
});

const props = defineProps<{
  item: CartApiItem,
  disableInteraction?: boolean,
  isOrderProposal?: boolean,
  showDiscountBtn?: boolean,
  hideDelete?: boolean,
  hideFavoriteAction?: boolean,
  hideMultiDiscount?: boolean,
  hideCrossDocking?: boolean,
}>();

const hasMixedProductsCampaign = computed(() => {
  return props.item.flags.find((f) => f.groupCode === 'percentageCampaignsMixedProducts') !== null;
});

const quantity = ref<number>(props.item.quantity);
const selectQuantity = ref<number>(props.item.quantity);
const disableEditing = computed(() => {
  if (!userStore.isSalesRepUser && !userStore.isCustomerSuccessUser && props.item.infoTypes.find((i) => i.name === NorceInfoTypesCodes.SalesRepDiscount)?.value) {
    return true;
  }

  return ((props.item.promotionIdSeed?.length ?? 0) > 0 || props.item.promotionIdSeed === '') && (props.item.priceInfo.priceBeforeVat === '0');
});

const hasRecommendedQuantity = ref(props.item.recommendedQty > 1);
const showQuantityInput = ref(!hasRecommendedQuantity.value && props.item.quantity > 25);

const onDemand = computed(()=> {
  return props.item.flags.some((s) => s.code === NorceFlagCodes.OnDemand);
});

// Fallback 1:, if there are more in cart than on hand, dropdown goes to cart qty
const maxAvailable = computed(()=> {
  if (globalStore.getAllowBackorders) {
    return 1000;
  }
  const onHandValue = globalStore.getAllowBackorders && props.item.onHandValue === 0 && props.item.stockStatus.estimatedDelivery
    ? props.item.stockStatus.incomingValue
    : props.item.onHandValue;

  return Math.max(props.item.quantity, onHandValue);
});

const quantityList = computed(()=> {
  if ((onDemand.value || globalStore.getAllowBackorders) && !hasRecommendedQuantity) {
    return 25;
  }

  const max = props.item.recommendedQty * 25;
  const step = props.item.recommendedQty;
  return [...Array(Math.floor(max/step) + 1).keys() ].map((i) => i * step);
});

const usedPoints = computed(()=>{
  const infoType = props.item.infoTypes.find((f) => f.name === 'tester_points_value');
  if (infoType) {
    return parseInt(infoType.value);
  }
  return -1;
});

const isCustomerSuccessProduct = computed(() => {
  return props.item.infoTypes.find(infoType => infoType.name === 'isCustomerSuccessProduct')?.value === 'true';
});

const customerSuccessProductMessage = computed(() => {
  return props.item.infoTypes.find(infoType => infoType.name === 'customerSuccessProductMessage')?.value || '';
});

const remove = () => {
  if (isCustomerSuccessProduct.value) {
    cartStore.updateCustomerSuccessProduct({ partNo: props.item.partNo, message: '' });
  } else {
    quantity.value = 0;
    updateQuantity(0);
  }
};
const updateQuantity = async(quantityInput: number) => {
  const res = await cartStore.updateCart(
    props.item.partNo,
    quantityInput,
    props.item.lineNo,
    false,
    props.item,
    true,
    '',
    props.item.priceListLocked
  );

  if (!res) {
    console.log('reset value');
    quantity.value = props.item.quantity;
    cartStore.isLoading = false;
  }
};

const onEnterQuantityInputDebounced = debounce((e: Event) => {
  const target = e.target as HTMLInputElement;
  let quantityNumber = parseInt(target.value);
  if (quantityNumber > maxAvailable.value && !onDemand.value) {
    uiStore.setTemporaryError($t('stock.maxCanAdd', { num: maxAvailable.value }), 3000);
    quantityNumber = maxAvailable.value;
  }
  updateQuantity(quantityNumber);
}, 500);

const onSelectQuantity = (e: Event) => {
  const target = e.target as HTMLSelectElement;
  const value = target.value;

  if (value === 'multiple') {
    showQuantityInput.value = true;
  } else {
    const quantityNumber = parseInt(value);
    updateQuantity(quantityNumber);
  }
};

const editProductDiscount = (async(item: CartApiItem) => {
  uiStore.setCurrentlyEditingItem(item);
  uiStore.setShowEditProductDiscountModal(true);
});

const salesRepOfferDiscount = computed((): number => {
  return Number(props.item.infoTypes?.filter(i => i.name === NorceInfoTypesCodes.SalesRepDiscount)[0]?.value) * 100 ?? 0;
});

const showCrossDockingInput = computed(() => {
  return !cartStore.isOrderProposalBasket && props.item.onHandValue < 1 && !props.hideCrossDocking;
});

const crossDockingValue = computed(() => {
  return props.item.infoTypes?.filter(i => i.name === NorceInfoTypesCodes.CrossDocking)[0]?.value == 'true';
});

const toggleCrossDocking = async() => {
  const res = await cartStore.updateCart(
    props.item.partNo,
    props.item.quantity,
    props.item.lineNo,
    false,
    props.item,
    true,
    '',
    props.item.priceListLocked,
    true
  );

  if (!res) {
    console.log('res failed', res);
  }
};

watch(
  () => props.item,
  () => {
    quantity.value = props.item.quantity;
    selectQuantity.value = props.item.quantity;
  }
);

</script>

<style scoped lang="postcss">

</style>
